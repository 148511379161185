import React, {useRef, useEffect, useState} from 'react'
import './statementReview.css'
import {

    useSearchParams,
  } from "react-router-dom"

import TokenService from '../utilities/token.service'
import API from "../utilities/api"
import ErrorWindow from '../errorWindow/errorWindow'
import Header from '../header/header'
import Navigation from '../navigation/navigation'
import UserStatement from '../userStatement/userStatement'

import ReportLogo from '../images/reportLogo.png'
import NoDoc from '../images/noDoc.png'
import MyResponse from '../myResponse/myResponse'


import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';


export default function StatementReview() {
    
    const [queryParameters] = useSearchParams()

    // ERROR RELATED ///////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////
    const [errorDisplay, setErrorDisplay] = useState('none')
    const [errorDetails, setErrorDetails] = useState({title: '', description: ''})

    function closeError(){
        setErrorDisplay('none')
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////

    // NAVIGATION RELATED ///////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////
    const [pageContentsDisplay, setPageContentsDisplay] = useState('block')
    const [navigationDisplay, setNavigationDisplay] = useState('none')

    const [pageState, setPageState] = useState('statementReview')

    function hidePage(){

      if(pageState == 'statementReview'){
        setPageContentsDisplay('none')
      } else if(pageState == 'response'){
        let tempResponseWindow = responseWindow
        tempResponseWindow.display = 'none'
        setResponseWindow(tempResponseWindow)
      }
        
        
        setNavigationDisplay('block')
    }

    function displayPage(){

      if(pageState == 'statementReview'){
        setPageContentsDisplay('block')
      } else if(pageState == 'response'){
        let tempResponseWindow = responseWindow
        tempResponseWindow.display = 'block'
        setResponseWindow(tempResponseWindow)
      }

        setNavigationDisplay('none')
    }
    /////////////////////////////////////////////////////////////////////////////////////////////////

    // SCROLLING RELATED ////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////
    const [scrollPosition, setScrollPosition ] = useState(0)


    const [mainScrollPosition, setMainScrollPosition ] = useState(0)

    const scrollReference = useRef()

    function updateScroll(){


        if(scrollReference.current.scrollTop + 1 >= (scrollReference.current.scrollHeight - scrollReference.current.clientHeight) && pageContentsDisplay == 'block'){
            console.log("get more")
            getReports(skipCount)
        }


        setScrollPosition({y: scrollReference.current.scrollTop, yMax: (scrollReference.current.scrollHeight - scrollReference.current.clientHeight)})
    }
    //=============================================================================================

    const [memberStatements, setMemberStatements] = useState([])
    const [skipCount, setSkipCount] = useState(0)
    const [reportLoad, setReportLoad] = useState(false)

    // First Render
    useEffect(() => {

      const onPageLoad = () => {
        console.log('page loaded');
        // do something else
        getReports(0)
        displayPage()


        window.scrollTo({ top: -30, behavior: "smooth" })
        
      };
  
      // Check if the page has already loaded
      if (document.readyState === 'complete') {
        onPageLoad();
      } else {
        window.addEventListener('load', onPageLoad, false);
        // Remove the event listener when component unmounts
        return () => window.removeEventListener('load', onPageLoad);
      }   

    }, []);



    async function getReports(skip){

      if(reportLoad){
        return
      }

      setReportLoad(true)

      let data = {
        skip: skip,
      }

      await API.post('getReports', data)
      .then(res => {

        setMemberStatements(prevState => [...prevState, ...res.data.reportList])
        setSkipCount(res.data.skipCount)

        console.log(res.data)

        setTimeout(() => {
          setReportLoad(false)
      }, 1000);
      })
      .catch(err => { 
          setErrorDetails(err)
          setErrorDisplay('block')
      })


    }

    const [responseWindow, setResponseWindow] = useState({display: 'none', information: {topVoteContent: {type: ''}}})

    async function responseHandler(info){
      setMainScrollPosition(scrollPosition.y)
      setPageState('response')
      setPageContentsDisplay('none')
      setResponseWindow({
        display: 'block', 
        information: info
      })
      scrollReference.current.scrollTop = 0
    }

    async function closeResponseWindow(){
      setResponseWindow({display: 'none', information: {topVoteContent: {type: ''}}})

      setPageState('statementReview')
      setPageContentsDisplay('block')
      

      console.log(mainScrollPosition)

      setTimeout(() => {
        scrollReference.current.scrollTop = mainScrollPosition
        setMainScrollPosition(0)
        
      }, 150);
  
      

    }



    // Open advertise

    return (
        <div className='screen_background' onScroll={updateScroll} ref={scrollReference} >
            <Header hideFunction={hidePage} openFunction={displayPage} navDisplay={navigationDisplay}></Header>
            <Navigation navigationDisplay={navigationDisplay} type={queryParameters.get("type")}></Navigation>
            <ErrorWindow display={errorDisplay} details={errorDetails} closeError={closeError}></ErrorWindow>

            <MyResponse display={responseWindow.display} information={responseWindow.information} closeWindow={closeResponseWindow} scrollPosition={scrollPosition}></MyResponse>

            <div style={{display: pageContentsDisplay}} className='statement_review_screen_foreground'>

            <div style={{display: memberStatements.length == 0 ? 'block' : 'none'}} className='statement_review_section'>
                <img className='statement_review_no_doc' src={NoDoc}></img>
                <div className='statement_review_text'>There are currently no reports at this time. Please check back tomorrow for an update.</div>     
            </div>


            {
                memberStatements.map((report) => 
                <div>
                    <div className='statement_review_section'>

                        <div className='statement_review_top_grid'>
                            <img className="statement_review_report_logo" src={ReportLogo}></img>
                            <div>
                              <div className='statement_review_type_text' style={{fontSize: '18pt'}}>{report.districtName} Session #{report.sessionNumber} Report</div>
                              <div className='statement_review_type_text'>Compiled on {report.readableDate}</div>
                            </div>
                        </div>

                        <hr style={{marginBottom: '10px'}}></hr>

                        
                        <div className='statement_review_within_section_title'>Constituent Statement</div>
                        
                        <UserStatement statementContent={report.topVoteContent} type={report.topVoteContent.type} session={'full'}></UserStatement>

                        <div className='statement_review_within_section_title'>Position Trend</div>

                        <ResponsiveContainer width={"100%"} height={"100%"} aspect={3}>
                            <AreaChart
   
                            data={report.positionCount}
                            margin={{
                                top: 0,
                                right: 0,
                                left: -60,
                                bottom: 0,
                            }}
                            >
                            <CartesianGrid strokeDasharray="2 2" />
                            <XAxis dataKey="Time" />
                            <YAxis tick={false} />
                            <Tooltip />
                            
                            <Area type="monotone" dataKey="Support" stackId="1" stroke="rgba( 119, 18, 162, 1)" fill="rgba( 119, 18, 162, 0.5)" />
                            <Area type="monotone" dataKey="Oppose" stackId="2" stroke="rgba(164, 164, 164, 1)" fill="rgba(164, 164, 164, 0.5)" />
                            </AreaChart>
                        </ResponsiveContainer>
   


                        <div style={{marginTop: '20px'}} className='statement_review_full_report_button' onClick={()=> window.open(report.reportURL, "_blank")}>View Full Report</div>
                        {/* <div>Click here to view full report</div> */}


                        
                        
                
                        <div className='statement_review_full_report_button' onClick={()=> responseHandler(report)}>Respond</div>
                

                        
                    </div>
                </div>
                
                
            )}




            </div>
        </div>
    )
}
