import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import './login.css'
import Logo from '../logo/logo.js'
import LoginPrompt from '../loginPrompt/loginPrompt'
import newLogo from '../images/2024_VoterStack_Logo.svg'

import TokenService from '../utilities/token.service'
import API from "../utilities/api"
import ErrorWindow from '../errorWindow/errorWindow'

export default function Login() {
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    // Error Related
    const [errorDisplay, setErrorDisplay] = useState('none')
    const [errorDetails, setErrorDetails] = useState({title: '', description: ''})

    function closeError(){
        setErrorDisplay('none')
    }

    // Login Handler Function
    async function loginHandler(phoneNumber, password){

        let data = {
            phoneNumber: phoneNumber,
            password: password
        }

        await API.post('officialLogin', data)
        .then(res => {
            console.log(res.data)
            TokenService.setUser(res.data)
            navigate('/statementReview')
        })
        .catch(err => { 
            setErrorDetails(err)
            setErrorDisplay('block')
        })

    }



    function goToLink(link){
        switch(link){
            case "signUp":
                navigate('/signUp')
                break
            case "support":
                navigate('/support')
                break
            case "policies":
                navigate('/policies')
                break
        }
    }

  return (
    <div className="screen_background background_image">



        <ErrorWindow display={errorDisplay} details={errorDetails} closeError={closeError}></ErrorWindow>
        <div className="login_screen_foreground">
                <div className="login_inner_section">                    
                    <div className="login_logo_section">
                        <img className='common_icon_center login_voterstack_logo' src={newLogo}></img>
                        <div className="login_voterstack_name_section">
                            <div className="login_voterstack_name">VoterStack</div>
                            <div className="login_voterstack_access_title">Government Official Access</div>
                        </div>
                    </div>             
                    <LoginPrompt loginHandler={loginHandler}></LoginPrompt>
            </div>


        </div>

        <div className='login_inline_span_parent'>
            <div className='login_span'>
                <div className="login_action_links" onClick={()=>goToLink("support")}>Support</div>
                <div className="login_action_links" style={{width: '20px', textAlign: 'center'}}>|</div>
                <div className="login_action_links" onClick={()=>goToLink("policies")}>Policies</div>
            </div>
        </div>
    </div>
  )
}
